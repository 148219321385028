import React from "react"
import PrivacyUganda from "../../components/body/pages/privacy/privacyUganda"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const PrivacyPageUg = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/legal/ug-privacy"}
      title="Privacy Policy | Kuda | The Money App for Africans"
    />
    <PrivacyUganda />
  </NoheaderFooter>
)

export default PrivacyPageUg
